import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

import { Injectable, OnInit } from "@angular/core";
import { BehaviorSubject, from, Observable, of, Subject, throwError } from "rxjs";
//import "rxjs/add/operator/map";
import { startWith, map, switchMap, filter, shareReplay, tap, debounceTime, distinctUntilChanged, retry, catchError } from 'rxjs/operators';

import { environment } from "../../environments/environment";
import { Note } from "../models/site-review/Note";
import { Report } from "../models/site-review/Report";
import { ReportItem } from "../models/site-review/ReportItem";
import { Question } from "../models/site-review/Question";
import { Polygon } from "app/models/site-review/Polygon";
import { Tract } from "app/models/site-review/Tract";
import { Assignment } from "app/models/site-review/Assignment";
import { AssignmentTeam } from "app/views/admin/teams-list/teams-list.component";
//import { splitAtColon } from "@angular/compiler/src/util";
import { AssignmentSchedule } from "app/models/site-review/AssignmentSchedule";
import { Job } from "app/models/site-review/Job";
import { Command } from "app/models/command";
import { TableMap } from './table-map';
import { Site } from 'app/models/site-review/Site';
import { GoogleFolderCommandSet } from 'app/models/google-folder-command-set';
import { DataStoreService } from './datastore.service';
import { IdbService } from './idb.service';
import { CalendarEvent } from 'app/models/calendar-event';
import { JobType } from 'app/interfaces/store-state';
import { ToolboxService } from 'app/toolbox.service';
import { JobEventListItem } from 'app/models/site-review/job-event-list-item';
import { ReportTemplate } from 'app/models/site-review/ReportTemplate';
import { User } from 'app/models/User';
import { merge } from 'jquery';
import { mergeAll } from 'rxjs-compat/operator/mergeAll';



export const apiServer = environment.siteReviewURL;// "https://sitereviewapi.azurewebsites.net";
export const pdfServer = environment.serverURL;
export const adobePDFkey = environment.adobePDFkey;
@Injectable()
export class SiteReviewService {
  public sessionId: string = "";
  public apiServer: string = "";
  public pdfServer: string = "";
  private sessionIdKey = 'sr_session_id';
  public selectedReport: Report;
  public tableMap: TableMap;
  private eventBrocker = new Subject<AppEvent<any>>();
  public adobePDFkey="";

  private supervisorsListSubject: Subject<User[]> = new Subject<User[]>();
  public supervisors$ = new Observable<User[]>();  //these are all supervisors at a crew level:  FM, GF and SUP..   property crewLevel tells them apart.

  /*  
 https://console.cloud.google.com/apis/dashboard?authuser=1&organizationId=339554518393&project=sitereview-1537283776749
 */
  public googleAPIKey: string = "AIzaSyD7gtowPV_w4QT11oiVAcet29mWzSrSsgU";

  public googleWorkingFolders: [] = [];

  constructor(private _http: HttpClient, private idb: IdbService, private toolbox: ToolboxService) {
    this.sessionId = localStorage.getItem(this.sessionIdKey);
    // this.apiServer = environment.serverURL;
    this.apiServer = apiServer;
    this.pdfServer = pdfServer;
    this.tableMap = new TableMap();
    this.adobePDFkey = adobePDFkey;

    // this.supervisors$ = this.supervisorsListSubject.asObservable();
    // this.loadSupervisors().subscribe();




  }
  loadSupervisors(): Observable<any> {

    let cmd = new Command();
    cmd.procedure = 'cmdGetSupervisorOptions';
    let url: string = this.apiServer + '/api/cmd?sid=' + this.sessionId + '&target=cmdGetSupervisorOptions';
    return this._http.post(url, cmd).pipe(

      tap(obj => {
        this.supervisorsListSubject.next(obj);
        //console.log('getSupervisors', obj)
      })
    );

  }



  /**
 * This will update a table in the SiteReview database.  You need to have the FieldName registered in the TableMap class
 * 
 * @param {string} fieldName the name of the field 
 * @param {any} value The value of the update.  It will be treated like a string unless it can be parsed as a number.
 * @param {string} critiera This is what will be after a "WHERE" statement in SQL when it is called.
 */
  public updateField(fieldName: string, value: any, critiera: string): Observable<any> {
    let table = this.tableMap.getMap(fieldName);

    var str = value;
    if (str !== null) {
      if (str.length > 0) {
        if (!isNaN(str)) {
          value = parseInt(str);
        }
      } else {
        value = value.replace("'", "''");
        value = "'" + value + "'";
      }
    } else {
      value = '';
    }
    if (table != '' && critiera != '') {
      let sql = `UPDATE ${table} SET ${fieldName} = ${value} WHERE ${critiera}`;
      let cmd = new Command();
      cmd.procedure = "cmdSQLUpdate";
      cmd.addParameter("Table", table);
      cmd.addParameter("SQL", sql);
      return this.command(cmd);
    } else {
      return new Observable();
    }

  }

  exportToPenta(timesheets){
    let postTimesheets = {
      "TimeSheets": timesheets
    }
    let url: string = `${this.apiServer}/api/penta/timesheet/generate-batch?sid=${this.sessionId}`;
    return this._http.post(url, postTimesheets)
    .map((response: any) => <any>response);
  }
  
  getBatchErrorsData(batchID){
    let url: string = `${this.apiServer}/api/penta/timesheet/errors?batchId=${batchID}&sid=${this.sessionId}`;
    return this._http.get(url)
    .map((response: any) => <any>response);
  }
  
  getBatchStatus(batchID){
    let url: string = `${this.apiServer}/api/penta/timesheet/status?batchId=${batchID}&sid=${this.sessionId}`;
    return this._http.post(url, null)
    .map((response: any) => <any>response);
  }

  executeBatch(batchID){
    let url: string = `${this.apiServer}/api/penta/timesheet?batchId=${batchID}&sid=${this.sessionId}`;
    return this._http.post(url, null)
    .map((response: any) => <any>response);
  }
  
  tryAgainBatch(batchID){
    let url: string = `${this.apiServer}/api/penta/timesheet-retry?batchId=${batchID}&sid=${this.sessionId}`;
    return this._http.post(url, batchID)
    .map((response: any) => <any>response);
  }

  spUpdatePentaBatchStatus(batchID){
    let cmd = new Command();
    cmd.procedure = 'spUpdatePentaBatchStatus';
    return this.command(cmd).map(updatedStatus => {
      console.log('googleWospUpdatePentaBatchStatusrkingFolders');
      return updatedStatus;
    });
  }

  public getPDF(url):Promise<any>{
   return this._http.get(url).toPromise();
  }
 
  public getMapLocation(fullAddress) {
    let url = `https://maps.googleapis.com/maps/api/geocode/json?address=${fullAddress}&sensor=false&key=${this.googleAPIKey}`
    return this._http.get(url);
  }

  openContractorBillingForm(jobID: number) {
    let server = this.pdfServer;
    let sid = this.sessionId;
    let url = `${server}/api/ContractorBillingForm?id=${jobID}&template=ContractorBilling&sid=${sid}`
    let w = window.open(url);
    w.focus();
  }


  public getDefaultGoolgeFolders() {
    let df = this.googleWorkingFolders;
    if (df.length === 0) {
      let cmd = new Command();
      cmd.procedure = 'cmdGetGoogleFolderDefaults';
      return this.command(cmd)
        .
        map(folders => {
          console.log('googleWorkingFolders');
          this.googleWorkingFolders = folders;
          return folders;
        });
    } else {
      return of<any>(df);
    }
  }
  public getGoogleStorageSignedURL(gsURI, minutes = 5): Observable<any> {
    let url = `${this.apiServer}/api/googlestorage?sid=${this.sessionId}`;
    return this._http.post(url, { file: gsURI, minutes: minutes });

  }
  public triggerJobPackageImport(folderId: string, importType: string = 'jobPackage') {
    var url;
    if (importType == 'PGEJobPackage') {
      url = `${this.apiServer}/api/AnalyzeJob?ParentFolderID=${folderId}&ImportType=PGEJobPackage&sid=${this.sessionId}`;
    } else {
      url = `${this.apiServer}/api/DistributeJobPackage/${folderId}?sid=${this.sessionId}`;
    }
    return this._http.get(url);
  }
  public bidEntry(): Observable<any> {
    let url = this.apiServer + `/api/bidentry?sid=${this.sessionId}`;
    var today = new Date();
    today.setHours(0, 0, 0, 0);

    let o = { bidRequestDate: today }
    console.log(url);
    return this._http.post(url, o);
  }



  public analyzeJobPDF(googleDriveFileID) {

    let url = `${this.apiServer}/api/JobAI?fileId=${googleDriveFileID}&ImportType=JobAnalyze&sid=${this.sessionId}`;

    return this._http.get(url);

  }

  public analyzeNewJobPDF(googleDriveFileID) {

    let url = `${this.apiServer}/api/AnalyzeJob/${googleDriveFileID}?sid=${this.sessionId}`;

    return this._http.get(url);

  }


  public preloadReport(o: any) {

    /*

    (string JobNumber, int LocationNumber, string Address1, string City, string State, string PostCode, string TemplateIDs, string sid

      */
    let url = this.apiServer + `/api/jobpreload?sid=${this.sessionId}`;
    console.log(o)
    console.log(url);
    return this._http.post(url, o);
  }

  public getJobs(): Observable<any> {
    let url = this.apiServer + "/api/Job?sid=" + this.sessionId;
    return this._http.get(url);
  }
  public getJob(id): Observable<any> {
    let url = this.apiServer + "/api/Job/" + id + "?sid=" + this.sessionId;
    return this._http.get(url);
  }

  getJobImages(id:number):Observable<any>{
    let cmd = new Command();
    cmd.procedure = 'cmdJobPhotos'
    cmd.addParameter('JobID',id);
    let pics$ = this.command(cmd);
    
    return pics$;
  }

    //THIS IS THE WAY TO SAVE ALL JOB PHOTOS
   uploadPhoto(photo: any): Observable<any> {
    photo.sessionId = this.sessionId;
    
      var url  = this.apiServer + '/api/Image?sid=' + this.sessionId;

      
    let retval$ = this._http.post(url,photo);
    return  retval$

  }
  deletePhoto(referenceCode:string,jobID:number):Observable<any>{
    var url  = this.apiServer + '/api/Image?referenceCode='+referenceCode+'&jobID='+jobID+'&sid=' + this.sessionId;      
    let retval$ = this._http.delete(url);
    return  retval$
  }
  async getJobStatusOptions(statusCode = 'JOB') {
    let cmd = new Command();
    cmd.procedure = "cmdGetJobStatus";
    return this.command(cmd).pipe(
      map(retval => {
        let options = retval.filter(row => row.statusCode === statusCode);
        return retval;
      }
      )).toPromise();
  }


  deleteJob(id) {

    //this.smart.fieldUpdate(this.myForm.value.jobID,"Status","Deleted") 
    let cmd = new Command();
    cmd.procedure = "cmdFieldUpdate";
    cmd.addParameter("Table", "Job");
    cmd.addParameter("Field", "Status");
    cmd.addParameter("Value", "DELETED");
    cmd.addParameter("Key", "JobID");
    cmd.addParameter("ID", id);
    return this.command(cmd).pipe(
      map(async (retval) => {

        await this.idb.delete('jobs', id).then(resp => console.log('deleted from idb', resp))

        return retval;
      })
    )
  }

  public saveJob(job: Job)  {
    alert("this script is no longer in use.  Please notify your development team if you see this message")
    // let url = this.apiServer + "/api/Job?sid=" + this.sessionId;

    // if (job.jobID) {
    //   return this._http.put(url, job);
    // } else {
    //   return this._http.post(url, job);

    // }
  }
  // this script is deprecated.
  public addNewSites(jobID: number, obj: any) {
    alert("this script is no longer in use.  Please notify your development team if you see this message")
    // let url = this.apiServer + "/api/SiteBuilder/" + jobID + "?sid=" + this.sessionId;
    //  return this._http.post(url, obj);
  }

  async newJob(obj) {
    let url = this.apiServer + "/api/job/create?sid=" + this.sessionId;
    return await this._http.post(url, obj).toPromise();

  }

   exportTimeSheet(ts:any){
      let endPoint = `${this.apiServer}/api/timesheet/export?sid=${this.sessionId}&id=${ts.timeSheetID}`;
    return this._http.get(endPoint);

  }
  createJobRecord(job,options:{rfp:boolean,jobPackage:string}) {
     
    let endPoint = `${this.apiServer}/api/job/add-record?sid=${this.sessionId}&rfp=${options.rfp}&jobPackage=${options.jobPackage}`;
    return this._http.post(endPoint, job);
  }

  setJobFolders(jobID) {
    let endPoint = `${this.apiServer}/api/job/${jobID}/add-folders?sid=${this.sessionId}`;
    return this._http.post(endPoint, null);
  }

  public newJobSites(jobID: number, locations: Site[]) {

    let url = this.apiServer + "/api/SiteBuilder/" + jobID + "?sid=" + this.sessionId;
    return this._http.post(url, locations);
  }
  public getJobSites(jobID: number): Observable<any> {
    let url = this.apiServer + "/api/SiteBuilder/" + jobID + "?sid=" + this.sessionId;
    return this._http.get(url);
  }
  public getSites(): Observable<any> {
    let url = this.apiServer + "/api/Site?siteid=0&sid=" + this.sessionId;
    return this._http.get(url);
  }
  public getSite(id): Observable<any> {
    let url = this.apiServer + "/api/Site?siteid=" + id + "&sid=" + this.sessionId;
    return this._http.get(url);
  }
  public addNewSite(site) {
    let url = this.apiServer + "/api/Site?sid=" + this.sessionId;
    return this._http.post(url, site)
      .pipe(map((res: any) => {

        return res[0];
      }));
  }
  public saveSite(site: Site) {
    let url = this.apiServer + "/api/Site?sid=" + this.sessionId;
    return this._http.put(url, site)
      .pipe(map((res: any) => this.returnFirstItem(res)));
  }

  public addSiteToSchedule(obj: AssignmentSchedule): Observable<any> {
    let url = this.apiServer + "/api/Schedule?sid=" + this.sessionId;
    return this._http.post(url, obj)
      .pipe(map((res: any) => this.returnFirstItem(res)));
  }
  public addNewReport(obj) {
    let url = this.apiServer + "/api/Report?sid=" + this.sessionId;
    return this._http.post(url, obj)
      .pipe(map((res: any) => this.returnFirstItem(res)));
  }
  public getReports(): Observable<any> {
    let url = this.apiServer + "/api/Report?reportid=0&sid=" + this.sessionId;
    return this._http.get(url);
  }

  public getReportById(reportId) {
    let url = this.apiServer + "/api/Report?reportid=" + reportId + "&sid=" + this.sessionId;
    return this._http.get(url);
  }

  public saveReport(report: Report): Observable<any> {
    if (report.reportID) {

      let url = this.apiServer + "/api/Report?reportid=" + report.reportID + "&sid=" + this.sessionId;
      return this._http.put(url, report);

    } else {

      let url = this.apiServer + "/api/Report?sid=" + + this.sessionId;
      return this._http.post(url, report);

    }
  }

  public getReportTemplates(): Observable<any> {
    let url = this.apiServer + "/api/ReportTemplate?ReportTemplateID=0&sid=" + this.sessionId;
    return this._http.get(url);
  }
  public getReportTemplateById(id): Observable<any> {
    let url = this.apiServer + "/api/ReportTemplate?ReportTemplateID=" + id + "&sid=" + this.sessionId;
    return this._http.get(url);
  }
  public saveReportTemplate(template: ReportTemplate): Observable<any> {
    let cmd = new Command();
    cmd.procedure = 'cmdReportTemplateSave';
    cmd.addParameter('ReportTemplateID', template.reportTemplateID);
    cmd.addParameter('reportName', template.reportName);
    cmd.addParameter('description', template.description || '');
    cmd.addParameter('templateFile', template.templateFile || '');
    cmd.addParameter('reportTemplateName', template.reportTemplateName || '');
    cmd.addParameter('googleStorageUrl', template.googleStorageUrl || '');
    cmd.addParameter('selfSend', template.selfSend || '');
    cmd.addParameter('impersonateUser', template.impersonateUser || '');
    cmd.addParameter('distributionTo', template.distributionTo || '');
    cmd.addParameter('distributionCC', template.distributionCC || '');
    cmd.addParameter('distributionFrom', template.distributionFrom || '');
    cmd.addParameter('status', template.status || 'Active');
    return this.command(cmd);
  }

  public uploadReportTemplateFile(templateID: number, data: any): Observable<any> {
    // if (templateID > 0) {
    let headers = new HttpHeaders();
    /** In Angular 5, including the header Content-Type can invalidate your request */
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    let url = this.apiServer + "/api/ReportTemplate/Upload/" + templateID + "/?sid=" + this.sessionId;
    return this._http.post(url, data, { "headers": headers });

    // } else {
    //     throw new Error('templateID should be greater than 0');
    // }
  }

  public uploadDashboardImg(dashboardItemID: number, data: any): Observable<any> {
    // if (templateID > 0) {
    let headers = new HttpHeaders();
    /** In Angular 5, including the header Content-Type can invalidate your request */
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    let url = this.apiServer + "/api/uploads/dashboardimage/" + dashboardItemID + "/?sid=" + this.sessionId;
    return this._http.post(url, data, { "headers": headers });

    // } else {
    //     throw new Error('templateID should be greater than 0');
    // }
  }


  saveReportTemplateItem(templateID, templateItem: Question): Observable<Question> {
    if (templateItem.questionID) {

      let url = this.apiServer + "/api/ReportTemplate/" + templateID + "/Question/" + templateItem.questionID + "?sid=" + this.sessionId;
      return this._http.put(url, templateItem)
        .map((res: any) => res);

    } else {
      let url = this.apiServer + "/api/ReportTemplate/" + templateID + "/Question?sid=" + this.sessionId;
      return this._http.post(url, templateItem)
        .pipe(map((res: any) => res));
    }
  }

  public getQuestionById(questionId) {
    let url = this.apiServer + "/api/Question?sid=" + this.sessionId + "&questionid=" + questionId;
    return this._http.get(url)
      .pipe(map((res: any) => {

        return res[0];
      }));
  }

  public saveQuestion(obj: Question) {
    let url = this.apiServer + "/api/Question?sid=" + this.sessionId;
    if (obj.questionID) {
      return this._http.put(url, obj);
    } else {
      return this._http.post(url, obj);
    }
  }

  // public addQuestionThenReportItem(obj: Question, reportItem: any) {
  //   let url = this.apiServer + "/api/Question?sid=" + this.sessionId;
  //   // console.log(reportItem)
  //   // return Observable.of({})
  //     return this._http.post(url, obj)
  //     .switchmap((res:any) => {
  //       res = res.json()
  //       let newReportItem: any = Object.assign(
  //         {},
  //         res,
  //         reportItem
  //       );
  //       return this.saveReportItem(newReportItem);
  //     }).map((res:any) => res.json());

  // }


  public getReportItemByID(reportItemID): Observable<ReportItem> {
    let url = this.apiServer + '/api/ReportItem?sid=' + this.sessionId + '&ReportItemID=' + reportItemID;
    return this._http.get(url)
      .pipe(map((res: any) => {

        return res[0];
      }));
  }


  public getReportItemNotesAndPhotosByID(reportItemID): Observable<any> {
    let url = this.apiServer + '/api/ReportItemNotesAndPhotos?id=' + reportItemID + '&sid=' + this.sessionId;
    return this._http.get(url)
      .pipe(map((res: any) => {

        return res;
      }));
  }



  public saveReportItem(obj: ReportItem) {
    let url = this.apiServer + "/api/ReportItem?sid=" + this.sessionId;
    if (obj.reportItemID) {
      return this._http.put(url, obj);
    } else {
      return this._http.post(url, obj);
    }

  }

  public updateReportItemResponse(obj: ReportItem) {
    let url = this.apiServer + "/api/SiteReviewResponse?sid=" + this.sessionId;
    if (obj.reportItemID) {
      return this._http.post(url, obj);
    } else {
      return this._http.post(url, obj);
    }

  }
  public getNotesByReport({ reportID, reportItemID }): Observable<Note[]> {
    if (this.selectedReport && this.selectedReport.reportID === reportID) {
      return Observable.of(
        this.filterNotes(this.selectedReport.notes, { reportItemID })
      );
    } else {
      return this.getReportById(reportID).map((res: Report) => {
        return this.filterNotes(res.notes, { reportItemID });
      });
    }
  }

  public getPhotosByReport({ reportID, reportItemID }): Observable<any[]> {
    if (this.selectedReport && this.selectedReport.reportID === reportID) {
      return Observable.of(
        this.filterPhotos(this.selectedReport.photos, { reportItemID })
      );
    } else {
      return this.getReportById(reportID).map((res: Report) => {
        return this.filterPhotos(res.notes, { reportItemID });
      });
    }
  }
  public getQuestionsByReport(reportID): Observable<any[]> {
    if (this.selectedReport) {
      console.log('if')
      return Observable.of(this.selectedReport.reportQuestions);
    } else {
      console.log('else', reportID)
      return this.getReportById(reportID)
        .pipe(map((res: any) => {
          return res.reportQuestions;
        }))
    }
  }



  public getAssignedSitesInTract(obj: any): Observable<any> {

    let url = this.apiServer + "/api/SitesAssignedInTract?sid=" + this.sessionId;
    return this._http.post(url, obj);
  }
  public getSitesByTract(id: number): Observable<any> {
    let url = this.apiServer + "/api/SiteInTract/" + id + '?sid=' + this.sessionId;
    return this._http.get(url);
  }



  public getSitesByPointSet(pointSet: string, tractID): Observable<any> {
    let obj: any = {};
    obj.pointSet = pointSet;
    obj.tractID = tractID;
    let url = this.apiServer + "/api/SiteInTract?sid=" + this.sessionId;
    return this._http.post(url, obj);
  }

  public savePolygon(polygon: Polygon) {
    console.log(polygon)
    return polygon;
  }

  public getPolygon1(id: number): Observable<any> {
    var cmd = {
      "procedure": "sitereview.dbo.getPolygon",
      "parameters": ["@PolygonID=" + id.toString(), "@SessionID='" + this.sessionId + "'"]

    }
    return this.command(cmd);

  }



  /*  ASSIGNMENT TEAMS */
  saveAssignmentTeam(t: AssignmentTeam) {

    let url = this.apiServer + "/api/AssignmentTeam?sid=" + this.sessionId;
    if (t.assignmentTeamID) {
      return this._http.put(url, t);
    } else {
      return this._http.post(url, t);
    }
  }
  getAssignmentTeam(id: number): Observable<AssignmentTeam[]> {

    let url = this.apiServer + "/api/AssignmentTeam/" + id + '?sid=' + this.sessionId;
    return this._http.get(url)
      .pipe(map((res: any) => res));
  }
  getAssignmentTeamMembers(id): Observable<any> {

    let criteria = {
      "AssignmentTeamMemberID": 1,
      "AssignmentTeamID": 0,
      "InternalContactsIDInput": 0
    }

    let url = `${this.apiServer}/api/AssignmentTeamMember?AssignmentTeamMemberID=0&AssignmentTeamID=${id}&InternalContactsIDInput=0&sid=${this.sessionId}`;
    return this._http.get(url);
  }

  addAssignmentTeamMember(obj: any) {

    let url = this.apiServer + "/api/AssignmentTeamMember?sid=" + this.sessionId;
    return this._http.post(url, obj);
  }

  saveAssignmentTeamMember(obj: any) {

    let url = this.apiServer + "/api/AssignmentTeamMember?sid=" + this.sessionId;
    return this._http.put(url, obj);
  }

  getAssignmentTeamPermissions(teamId: number) {
    var cmd = new Command();
    cmd.procedure = 'cmdGetTeamPermissions';
    cmd.addParameter('AssignmentTeamID', teamId);
    return this.command(cmd).pipe(map((res: any) => res));
  }

  addAssignmentTeamPermission(teamId: number, url: string) {
    var cmd = new Command();
    cmd.procedure = 'cmdAddTeamPermission';
    cmd.addParameter('AssignmentTeamID', teamId);
    cmd.addParameter('GoogleURL', url);
    return this.command(cmd)
  }

  removeAssignmentTeamPermission(Id: number) {
    var cmd = new Command();
    cmd.procedure = 'cmdDeleteTeamPermission';
    cmd.addParameter('AssignmentTeamPermissionID', Id);
    return this.command(cmd)
  }

  newJobPackage(jobID: number) {
    let url = this.apiServer + "/api/jobs/" + jobID + "/submissions/new?sid=" + this.sessionId;
    return this._http.post(url, {});
  }
  autoCreateSubmission(jobID: number, GoogleFileID: string): Observable<any> {
    let url = this.apiServer + "/api/jobs/" + jobID + "/submissions/auto?sid=" + this.sessionId;
    let data = { jobID: jobID, googleFileID: GoogleFileID }
    return this._http.post(url, data);
  }
  submitJobPackage(jobID: number, submissionID: number, email: { to: string, subject: string, message: string }) {
    let url = this.apiServer + "/api/jobs/" + jobID + "/submissions/" + submissionID + "?sid=" + this.sessionId;

    return this._http.put(url, email)
      .pipe(map((res: any) => res));
  }
  infractionOptions() {
    var cmd = new Command();
    cmd.procedure = 'cmdInfractionOptions';
    return this.command(cmd)
  }



  submitJobPackageByFTP(obj: any, host: any) {

    let api = '/api/sftp'
    let submission = {
      submissionID: obj.submissionID,
      remotePath: '',
      hostID: host.hostID
    }
    let url = this.apiServer + api + '?sid=' + this.sessionId;

    return this._http.post(url, submission);

  }
  googleFolderList(folderId: string) {
    let url = this.apiServer + '/api/GoogleDrive/Folder/' + folderId + '?sid=' + this.sessionId;
    return this._http.get(url).map((res: any) => res);
  }

  uploadJobSubmissionFileFromGoogleDrive(jobID: number, submissionID: number, googleFileUrls: string[]) {

    let url = `${this.apiServer}/api/jobs/${jobID}/submissions/${submissionID}/driveupload?sid=${this.sessionId}`;

    return this._http.post(url, googleFileUrls);

  }
  makeGoogleFolder(folderObject: GoogleFolderCommandSet): Observable<any> {


    let url = this.apiServer + "/api/GoogleDrive/Folders?sid=" + this.sessionId;
    return this._http.post(url, folderObject);
  }
  renameGoogleFolder(folderId: string, name: string): Observable<any> {


    let url = `${this.apiServer}/api/GoogleDrive/Folders/${folderId}?name=${name}&sid=${this.sessionId}`;
    return this._http.put(url, {});
  }
  ResetJobFolderLocations(jobId: number): Observable<any> {

    //let obj: any = { "JobID": jobId };
    //let url = this.apiServer + "/api/GoogleDrive/ResetCustomer?sid=" + this.sessionId;
    let url = `${this.apiServer}/api/drivefolder/job/reset/?sid=${this.sessionId}&jobId=${jobId}`;

    return this._http.get(url);

  }
  copyTemplateFile(templateCode, targetFolderID):Observable<any>{
    let url = `${this.apiServer}/api/filecopy?templateName=${templateCode}&targetFolder=${targetFolderID}&sid=${this.sessionId}`;
    return this._http.post(url,{});
  }

  resetJobFolderShortCuts(bidFolderID: string): Observable<any> {
    let url = `${this.apiServer}/api/GoogleDrive/FileFolder/${bidFolderID}/shortcuts?command=rebuild&sid=${this.sessionId}`
    return this._http.post(url, null);
  }
  resetJobFolderNames(jobId: number): Observable<any> {
    let url = this.apiServer + "/api/GoogleDrive/ResetJobFolderNames/" + jobId + "?sid=" + this.sessionId;
    return this._http.get(url);
  }
  async getJobTypes() {

    var cmd = new Command();
    cmd.procedure = "giot";  // WTF is that?
    cmd.procedure = "cmdGetJobTypes";
    let url: string = this.apiServer + '/api/cmd?tracker=' + cmd.procedure + '&sid=' + this.sessionId;
    return this._http.post(url, cmd)
      .pipe(map((res: any) => res));
  }

  //  async getVendorList(category){

  //   const state = this.getState();
  //   if (state && state.vendors) return state.vendors;

  //   var cmd: Command = new Command();
  //   cmd.procedure = 'cmdVendorList';
  //   cmd.addParameter('Category', category);

  //   return this.command(cmd).pipe
  //     (map(vendors => {
  //       this.setState({ vendors }, VehicleStoreActions.GetVehicles)
  //       return vendors;
  //     })).toPromise();   
  // }



  getUsers(id?: number): Observable<AssignmentTeam[]> {

    let url = this.apiServer + "/api/user?sid=" + this.sessionId;
    return this._http.get(url)
      .pipe(map((res: any) => res));
  }
  public getUser(id: number): Observable<any> {
    let url: string = this.apiServer + '/api/User/' + id + '?sid=' + this.sessionId;
    return this._http.get(url)
      .pipe(map((response: any) => <any>response));
  }
  public getUserList(): Observable<any> {
    let url: string = this.apiServer + '/api/User' + '?sid=' + this.sessionId;
    return this._http.get(url)
      .pipe(map((res: any) => {
        let users: any[] = res;
        return users.map(user => {
          user.FullName = user.FirstName + ' ' + user.LastName;
          return user;
        });
      }));
  }

  public saveUser(user) {
    if (user.InternalContactsID) {
      let url: string = this.apiServer + '/api/User/' + user.InternalContactsID + '?sid=' + this.sessionId;
      return this._http.put(url, user)
        .pipe(map((response: any) => <any>response));
    } else {
      let url: string = this.apiServer + '/api/User?sid=' + this.sessionId;
      return this._http.post(url, user)
        .pipe(map((response: any) => <any>response));
    }

  }


  public updateUserCalendar(calendarList: any[]) {
    let url: string = this.apiServer + '/api/user/calendar' + '?sid=' + this.sessionId;
    return this._http.post(url, calendarList)
      .pipe(map((response: any) => <any>response));
  }

  saveAssignment(a: Assignment): Observable<Assignment> {

    let siteIDArray: string = a.siteIDArray;
    let obj: any = {};
    obj.siteIDArray = siteIDArray;
    obj.assignmentName = a.assignmentName;
    obj.assignmentTeamID = a.assignmentTeamID;
    obj.tractID = a.tractID;
    obj.effectiveDate = a.effectiveDate;


    let url = this.apiServer + "/api/SiteAssignment?sid=" + this.sessionId;
    if (a.assignmentID) {
      return this._http.put(url, obj)
        .pipe(map((res: any) => res));
    } else {
      return this._http.post(url, obj)
        .pipe(map((res: any) => res));
    }
  }
  getAssignmentList() {

    let url = this.apiServer + "/api/Assignment?sid=" + this.sessionId;
    return this._http.get(url)
      .pipe(map((res: any) => res));
  }
  getAssignment(id: number): Observable<Assignment> {

    let url = this.apiServer + "/api/SiteAssignment/" + id + '?sid=' + this.sessionId;
    return this._http.get(url)
      .pipe(map((res: any) => res));
  }
  getTractStatistics(id): Observable<Tract> {

    let url = this.apiServer + "/api/SiteInTractStats/" + id + '?sid=' + this.sessionId;
    return this._http.get(url)
      .pipe(map((res: any) => res));
  }
  getTract(id): Observable<Tract> {

    let url = this.apiServer + "/api/Tract/" + id + '?sid=' + this.sessionId;
    return this._http.get(url)
      .pipe(map((res: any) => res));
  }
  getTracts(): Observable<Tract[]> {

    let url = this.apiServer + "/api/Tract?sid=" + this.sessionId;
    return this._http.get(url)
      .pipe(map((res: any) => res));
  }
  saveTract(t: Tract) {

    let url = this.apiServer + "/api/Tract?sid=" + this.sessionId;
    if (t.tractID) {
      return this._http.put(url, t)
        .pipe(map((res: any) => res));
    } else {
      return this._http.post(url, t)
        .pipe(map((res: any) => res));
    }

  }

  getCustomers(): Observable<any> {
    let cmd = new Command();
    cmd.procedure = 'cmdCustomers';

    return this.command(cmd)

  }


  getReportList(reportName: string): Observable<any> {
    let cmd = new Command();
    cmd.procedure = 'getReport';
    cmd.addParameter("ReportName", reportName)
    return this.command(cmd);
  }

  async getCustomersAsync() {
    let cmd = new Command();
    cmd.procedure = 'cmdCustomers';
    return await this.command(cmd).toPromise()
  }

  async getWorkDescriptionOptions() {
    let cmd = new Command();
    cmd.procedure = 'cmdWorkDescriptionOptions';
    return await this.command(cmd).toPromise()
  }

  async checkJobDuplication(
    obj: { jobNumber: string, notificiationNumber: string, customerID: number, workDescription: string })
    : Promise<[{ jobID: number, jobNumber: number, notificiationNumber: number, address1: string, customerName: string }]> {
    let cmd = new Command();
    cmd.procedure = 'cmdValidateJob';
    cmd.addParameter("JobNumber", obj.jobNumber);
    cmd.addParameter("NotificationNumber", obj.notificiationNumber);
    cmd.addParameter("CustomerID", obj.customerID)
    cmd.addParameter("WorkDescription", obj.workDescription)
    return await this.command(cmd).toPromise()
  }

  slacker(msg: string, error: string = 'none') {

    let url = this.apiServer + '/api/logger/slack';
    let loc = window.location.href;
    let session = JSON.parse(localStorage.getItem("session"))
    let user = session.idTokenPayload
    var message = {
      "text": "Dash Logger",
      "blocks": [
        {
          "type": "section",
          "text": {
            "type": "mrkdwn",
            "text": msg
          }
        },
        {
          "type": "section",
          "text": {
            "type": "mrkdwn",
            "text": "<" + loc + "|" + loc + ">"
          }
        },
        {
          "type": "section",
          "fields": [
            {
              "type": "mrkdwn",
              "text": "*Details*\n" + error
            }
          ]
        },
        {
          "type": "section",
          "fields": [
            {
              "type": "mrkdwn",
              "text": "*User*\n" + JSON.stringify(user)
            }
          ]
        }
      ]
    }
    console.log(message);
    // var message = {"text":"Hello, World!"}
    this._http.post(url, message).subscribe();

  }
  addCustomer(c): Observable<any> {

    let url = this.apiServer + "/api/Customer?sid=" + this.sessionId;

    return this._http.post(url, c)
      .pipe(map((res: any) => res));



  }
  getCustomer(customerID): Observable<any> {

    let cmd = new Command();
    cmd.procedure = 'cmdCustomers';
    cmd.addParameter('CustomerID', customerID)

    return this.command(cmd)


  }
  getMaterials(id: number = 0) {
    let cmd = new Command();
    cmd.procedure = 'cmdMaterial';
    cmd.addParameter('MaterialID', id);
    cmd.addParameter('Code', '');
    cmd.addParameter('Name', '');
    cmd.addParameter('Status', '');
    cmd.addParameter('Action', 'SELECT');
    return this.command(cmd)

  }

  getBids() {
    // let cmd = new Command();
    // cmd.procedure="cmdBidList";
    // return this.command(cmd)

    let url: string = this.apiServer + '/api/bidentry?sid=' + this.sessionId;

    return this._http.get(url)
      .pipe(map((res: any) => res));

  }





  getBid(id: number) {
    let cmd = new Command();
    cmd.procedure = "cmdBid";
    cmd.addParameter("BidID", id);
    return this.command(cmd)


  }
  getBidEntry(jobID: number) {
    let url: string = this.apiServer + '/api/bidentry/' + jobID + '?sid=' + this.sessionId;

    return this._http.get(url)
      .pipe(map((res: any) => res));

  }
  saveBid(bid: any) {

    let url: string = this.apiServer + '/api/bid?sid=' + this.sessionId;

    return this._http.post(url, bid)
      .pipe(map((res: any) => res));
    //  .do(x => console.log(x));
  }

  saveBidEntry(bid: any) {
    console.log("saveBidEntry", bid)
    let url: string = this.apiServer + '/api/bidentry?sid=' + this.sessionId;

    return this._http.put(url, bid)
      .pipe(
        map((response: Response) => <any>response)
      )

    //  .do(x => console.log(x));
  }

  addLocation(job) {
    /*
    [dbo].[newSite]
(	@Latitude				VARCHAR(20)
,	@Longitude				VARCHAR(20)
,	@SiteName				VARCHAR(64)
,	@LocationNumber			int
,	@Address1				VARCHAR(64)
,	@Address2				VARCHAR(64)
,	@City					VARCHAR(64)
,	@State					VARCHAR(2)
,	@PostCode				VARCHAR(16)
,	@JobNumber				VARCHAR(55)
*/
    let url: string = this.apiServer + '/api/bidentry/?sid=' + this.sessionId;

    return this._http.get(url)
      .pipe(map((res: any) => res));

  }

  addLocationFolder(jobID: number) {

    let url: string = this.apiServer + '/api/joblocation/' + jobID + '?sid=' + this.sessionId;

    return this._http.get(url)
      .pipe(map((res: any) => res));

  }

  command(commandObject: any): Observable<any> {

    let url: string = this.apiServer + '/api/cmd?tracker=' + commandObject.procedure + '&sid=' + this.sessionId;
    return this._http.post(url, commandObject)
      .pipe(map((res: any) => res));
    //  .do(x => console.log(x));
  }


  commandList(commandObject: any): Observable<any> {

    let url: string = this.apiServer + '/api/cmd/list?tracker=' + commandObject.procedure + '&sid=' + this.sessionId;
    return this._http.post(url, commandObject)
      .pipe(map((res: any) => res));
    //  .do(x => console.log(x));
  }





  private filterNotes(noteList: Note[], options: Note): Note[] {
    options = options || {};
    options.status = options.status || "Active";
    // options.noteType = options.noteType || 'report';

    if (options.reportItemID) {
      noteList = noteList.filter((n: Note) => {
        return (
          n.status === options.status && n.reportItemID === options.reportItemID
        );
      });
    } else {
      // console.log(noteList);
      noteList = noteList.filter(n => {
        return n.status === options.status && !n.reportItemID;
      });
    }
    return noteList;
  }
  public filterPhotos(photos: any[], options: any = {}): any[] {
    options = options || {};
    options.status = options.status || "Active";
    // console.log(options.reportItemID);
    if (options.reportItemID) {
      photos = photos.filter(p => {
        return (
          p.status === options.status && p.reportItemID === options.reportItemID
        );
      });
    } else {
      photos = photos.filter(p => {
        return p.status === options.status && !p.reportItemID;
      });
    }

    return photos;
  }

  // CCSC and  DOCUMENT 
  getDocFieldsets(reportID, docID): Observable<any> {
    let url = this.apiServer + "/api/report/" + reportID + "/document/" + docID + "?sid=" + this.sessionId;
    return this._http.get(url)
      .pipe(map((res: any) => res));
  }
  saveDocFieldsets(reportID, docID, fieldSets: any[]): Observable<any> {
    let url = this.apiServer + "/api/report/" + reportID + "/document/" + docID + "?sid=" + this.sessionId;
    return this._http.post(url, fieldSets)
      .pipe(map((res: any) => res));
  }

  returnFirstItem(items) {
    items = items;
    return items[0] || {};
  }
  async lookupCCMMaster(jobNumber: number, notificationNumber: number) {
    let url = `${this.apiServer}/api/job/ccm/${jobNumber}/${notificationNumber}?sid=${this.sessionId}`;
    return this._http.get(url).toPromise();

  }
  async getOptions(optionName: string) {
    let cmd = new Command();
    cmd.procedure = 'cmdOptionList';
    cmd.addParameter('OptionName', optionName)
    return this.command(cmd).toPromise();
  }

  loadJobEventByEventID(eventID: number): Observable<JobEventListItem> {
    let url = `${this.apiServer}/api/job/event?sid=${this.sessionId}&eventid=${eventID}`;
    let event = <Observable<JobEventListItem>>this._http.get(url).pipe(
      tap((obj: any)  => {
        console.log('tap event type', obj.eventType)
      }
      
      )
      ,  map((obj: any) => {
        console.log('map event type',  obj.eventType)
        return obj;
      })
   , tap((obj: any)  => { console.log('tap event type',  obj.eventType) }));
    return event;
  }
  async loadJobEvents(jobID: number): Promise<JobEventListItem[]> {
    //  let url = `${this.apiServer}/api/job/${jobID}/events/?sid=${this.sessionId}`;
    //  let events =  this._http.get(url)
    //   .pipe(
    //     map((retval:JobEventListItem[])=>{
    //       return retval;
    //     })
    //   ).toPromise();

    //   let cmd = new Command();
    //   cmd.procedure = 'cmdJobEventList';
    //   cmd.addParameter('JobID',jobID)
    //  let events= this.command(cmd)
    //     .pipe(
    //       map((retval:JobEventListItem[])=>{
    //         return retval;
    //       })
    //     ).toPromise();


    //  return events;
    alert('this is the incorrect procedure for loading events');
    return null;
  }
  async deleteJobEvent(eventID: number): Promise<any> {

    let url = `${this.apiServer}/api/job/events/${eventID}/delete?sid=${this.sessionId}`;
    return this._http.post(url, null).toPromise();

  }

  loadJobEventsOb(jobID: number): Observable<JobEventListItem[]> {
    let url = `${this.apiServer}/api/job/${jobID}/events/?sid=${this.sessionId}&id=${jobID}`;
    let events = this._http.get(url)
      .pipe(
        map((retval: JobEventListItem[]) => {
          return retval;
        })
      )
    return events;
  }
  serverSideEventDescription(fields) {
    let url = `${this.apiServer}/api/job/36741/event/describe`;
    return this._http.post(url, fields);

  }

  getRowData(jobID) {
    let cmd = new Command();
    cmd.procedure = 'cmdJobEvent';
    cmd.addParameter("JobID", jobID);
    return this.command(cmd);
  }
  getCCM() {
    let cmd = new Command();
    cmd.procedure = 'cmdCCMUpload';
    return this.command(cmd);
  }
  getCCMTags(lastLoad: Date) {
    let cmd = new Command();
    //cmd.procedure = 'cmdCCMTagList';
    lastLoad.setMinutes(lastLoad.getMinutes() - 10);

    let updatedDate = this.toolbox.formatJsToSqlDate(lastLoad);
    cmd.procedure = 'cmdCCMList';
    cmd.addParameter('LastChangedDate', updatedDate);
    return this.command(cmd);

  }
  createCalendarEvent(jobID: number, event: CalendarEvent) {
    let url = `${this.apiServer}/api/job/${jobID}/Event/?eventType=${event.eventType}&sid=${this.sessionId}&startDateColumn=${event.startDateColumn}&endDateColumn=${event.endDateColumn}`;
    return this._http.post(url, event)
      .pipe(map((res: any) => res));
  }

  async cancelCalendarEvent(jobID: number, eventID: number, reason: string = '') {

    //{{server}}/api/job/1/events/244/cancel?sid={{sid}}
    let url = `${this.apiServer}/api/job/${jobID}/events/${eventID}/cancel?reason=${reason}&sid=${this.sessionId}`;
    return this._http.put(url, {})
      .pipe(map((res: any) => res)).toPromise();
  }

  getCalendarList() {

    let url: string = this.apiServer + '/api/calendar/?sid=' + this.sessionId;
    return this._http.get(url)
      .pipe(
        tap(obj=>{console.log('original calendar download',obj);}),
        map((res: any) => res));
  }


  getUserCalendarList(email: string) {
    let url: string = this.apiServer + '/api/calendar/userroles/' + email + '?sid=' + this.sessionId;
    return this._http.get(url)
      .pipe(map((res: any) => res));
  }


  getCalendar(id) {
    let url: string = this.apiServer + '/api/calendar/' + id + '?sid=' + this.sessionId;
    return this._http.get(url)
      .pipe(map((res: any) => res[0]));
  }
  updateCalendar(obj) {
    let url: string = this.apiServer + '/api/calendar/?sid=' + this.sessionId;
    return this._http.put(url, obj)
      .pipe(map((res: any) => res));
  }
  newCalendar(obj) {

    let url: string = this.apiServer + '/api/calendar/?sid=' + this.sessionId;
    return this._http.post(url, obj)
      .pipe(map((res: any) => res));
  }
  importPackageHistory(importPackageID) {
    let cmd = new Command();
    cmd.procedure = 'cmdImportPackageHistory';
    cmd.addParameter('ImportPackageID', importPackageID);
    return this.command(cmd);
  }


  public getDashboardData() {
   // console.log('running cmd dashBoardData...')
    var cmd = new Command();
    cmd.procedure = "cmdGetDashboard";
    cmd.addParameter("DashboardID", 6);
    cmd.addParameter("DashboardType", "DASH");
    return this.command(cmd)
  }

  timeSheetAdminReport(weekNum, foremanID, yearNumber) {
    //let url: string = this.apiServer + '/api/timesheet/approval?sid=' + this.sessionId + '&weekNumber=' + weekNum;
    let url: string = this.apiServer + '/api/timesheet/approval?sid=' + this.sessionId + '&weekNumber=' + weekNum + '&foremanID=' + foremanID + '&yearNumber=' + yearNumber;
    return this._http.get(url)
      .pipe(map((res: any) => res));
  }

  timeSheetValidation(timeSheetID) {
    let url = this.apiServer + '/api/timesheet/' + timeSheetID + '/validation?sid=' + this.sessionId;
    return this._http.get(url);
  }

  processTimeSheetsToPenta(processedTSs){
    let url = this.apiServer + '/api/pentarelay/process-batch?sid=' + this.sessionId;
    return this._http.post(url, processedTSs)
      .pipe(map((res: any) => res));
  }

  processUploadJobFile(file, url) {
    return this._http.post(url, file)
      .pipe(map((res: any) => res));
  }

  jobFileList(jobid, location = 'GD') {
    if (location === 'GD') {
      let url = `${this.apiServer}/api/GoogleDrive/JobFolders/${jobid}/list?sid=${this.sessionId}`
      return this._http.get(url)
        .pipe(map((res: any) => res));

    } else {

      let url = `${this.apiServer}/api/Archive/Job/${jobid}/List?sid=${this.sessionId}`
      return this._http.get(url)
        .pipe(map((res: any) => res));

    }

  }
  archiveJobFiles(jobid, deleteFromGD = true, setJobToArchived = false) {

    let url = `${this.apiServer}/api/Archive/Job/${jobid}/StoreFolder?deleteFromGD=${deleteFromGD}&setJobToArchived=${setJobToArchived}&sid=${this.sessionId}`
    return this._http.post(url, null)
      .pipe(map((res: any) => res));
  }
  restoreJobFiles(jobid, deleteFromGS = true, setJobToActive = false, rebuildDirectory = false, rebuildShortcuts = false) {
    //  {{server}}/api/Archive/Job/{{JobID}}/Restore?deleteFromGS=true&setJobToActive=false&rebuildDirectory=false&rebuildShortcuts=false&sid={{sid}}
    let url = `${this.apiServer}/api/Archive/Job/${jobid}/Restore?deleteFromGS=${deleteFromGS}&setJobToActive=${setJobToActive}&rebuildDirectory=${rebuildDirectory}&rebuildShortcuts=${rebuildShortcuts}&sid=${this.sessionId}`
    return this._http.post(url, null)
      .pipe(map((res: any) => res));

  }


  analyzeJobData(jobDataID) {
    let url = `${this.apiServer}/api/AnalyzeJob/${jobDataID}?sid=${this.sessionId}`
    return this._http.post(url, null)
      .pipe(map((res: any) => res));
  }


  processFile(url) {
    return this._http.post(url, null)
      .pipe(map((res: any) => res));
  }

  //Envents methods
  on(eventType: AppEventType): Observable<AppEvent<any>> {
    return this.eventBrocker.pipe(filter(event => event.type === eventType));
  }

  dispatch<T>(event: AppEvent<T>): void {
    this.eventBrocker.next(event);
  }


  sendClientCommand(commandName, commandMessage) {
    let url = `${this.apiServer}/api/signal/broadcast?commandName=${commandName}&sid=${this.sessionId}`;
    return this._http.post(url, commandMessage)
  }


  getUSATicketsJobDetails(pMNumber){
    let url = `https://appsca.undergroundservicealert.org/posr/searchtool/PositiveResponse/GetJobsListForTable?format=json&ticketNumber=%${pMNumber}%20`
    return this._http.get(url)
    .pipe(map((res: any) => res));
  }
  getChunk(beginDate, endDate){
 

    let strBeginDate = this.toolbox.formatJsToSqlDate(beginDate);
    let estrEndDate = this.toolbox.formatJsToSqlDate(endDate);

    let url = `${this.apiServer}/api/jobmaster/chunk?begindate=${strBeginDate}&enddate=${estrEndDate}&sid=${this.sessionId}`;
    return this._http.post(url,{})  
  }

  

  // getJobsGoogleJson(){
  //   let url = `${this.apiServer}/api/jobmaster/cache-link?sid=${this.sessionId}&key=sbqtwgyqvsrcbwcb6rf87b`
  //   return this._http.get(url)
  //   .pipe(map((res: any) => res))
  //   .subscribe(res => {
  //     let URLjsonFile = res.gsURL
  //     this._http.get(URLjsonFile)
  //     .pipe(
  //       retry(3), // Retry up to 3 times
  //       catchError(this.handleError)
  //     )
  //     .subscribe({
  //       next: (data) => {
  //         console.log('Data received:', data);
  //         // Handle the data here
  //       },
  //       error: (error) => {
  //         console.error('Error fetching data:', error);
  //         // Handle any errors here
  //       }
  //     });
  //   })
  // }

  // getJobsGoogleJson(): Observable<any> {
  //   const url = `${this.apiServer}/api/jobmaster/cache-link?sid=${this.sessionId}&key=sbqtwgyqvsrcbwcb6rf87b`;
    
  //   return this._http.get(url).pipe(
  //     map((res: any) => res.gsURL),
  //     switchMap((URLjsonFile: string) => 
  //       from(fetch(URLjsonFile, { mode: 'no-cors' }).then(response => response.text())).pipe(
  //         retry(3),
  //         catchError(this.handleError)
  //       )
  //     )
  //   );
  // }

  getJobsGoogleJson(): Observable<any> {
    const url = `${this.apiServer}/api/jobmaster/cache-link?sid=${this.sessionId}&key=sbqtwgyqvsrcbwcb6rf87b`;
    
    return this._http.get(url).pipe(
      map((res: any) => res.gsURL),
      switchMap((URLjsonFile: string) => 
        this._http.get(URLjsonFile).pipe(
          retry(3),
          catchError(this.handleError)
        )
      )
    );
}


  getJsonJobs(url){
    // return this._http.get(url)
    // .pipe(map((res: any) => res))
    fetch(url, { mode: 'no-cors' }).then(res => {
      console.log('fetch res getJsonJobs ', res)
      return res;
    })
  }

  handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, body was: `, error.error);
    }
    // Return an observable with a user-facing error message.
    return throwError(() => new Error('Something bad happened; please try again later.'));
  }


}


//let's create an event system in this api
export enum AppEventType {
  ApiCalls = 'ALL_CALL',
  ApiUpdate = 'ALL_UPDATES',
}

export class AppEvent<T> {
  constructor(
    public type: AppEventType,
    public entityName: string,
    public payload: T,
  ) { }
}


